import React, { useState, useEffect } from 'react'
import { useScrollAnim } from "src/components/hooks/hooks"


export function FacilitiesDetailList({ data, category }) {
    const [trigger, anim] = useScrollAnim()

    const grouping = (allData) => {
        // grouping
        let groups = {}
        allData?.forEach((item) => {
            const dataCategory = item.facilityCategories.nodes[0].slug
            if (dataCategory === category) {
                let initial = groups[item.title[0].toLowerCase()]
                if (!initial) groups[item.title[0].toLowerCase()] = []
                groups[item.title[0].toLowerCase()].push(item.title)
            }
        })
        return groups
    }

    const [items, setItems] = useState([])

    useEffect(() => {
        setItems(grouping(data))
    }, [data])

    return (
        <section className="py-main" ref={trigger}>
            <div className="container mw-xl">
                <div className="row">
                    {Object.keys(items)?.map((item, i) => {
                        return (
                            <div className="py-4 col-md-12 row" key={i}>
                                <h3 className={`text-uppercase col-md-12 border-bottom pb-3 ${anim(1 + i)}`} >
                                    {item}
                                </h3>
                                {
                                    items[item]?.map((name, j) => {
                                        return (
                                            <div key={j} className="col-md-6 pt-3">
                                                <p className={`font-weight-light text-gray-600 ${anim(2 + j)}`}>
                                                    {name}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
