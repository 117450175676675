import React, { useEffect, useState } from 'react'

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { FacilitiesDetailList } from "components/pages/facilities/detail-list.js"


export default function FacilitiesDetail({ location, pageContext }) {
	const lang = pageContext.langKey
	const data = pageContext.data
	const dataLang = lang === "en" ? pageContext?.category : pageContext?.category?.translation || pageContext?.category
	const dataLangSc = lang === "en" ? pageContext?.scCategory : pageContext?.scCategory?.translation || pageContext?.scCategory
	const coverCat = dataLang?.facilityCategoriesPage?.cover
	const coverSc = dataLangSc?.facilityCategoriesPage?.cover
	const bgColor = dataLang.backgroundColor.backgroundColor.color
	const linkArray = location.pathname.split("/")
	const breadPath = [...new Set(
		linkArray.map((el, i) =>
			i !== (pageContext.langKey === 'id' ? 3 : 2)
				? el : '')
	)].join('/')
	const eduCategory = linkArray[linkArray?.length - 1]
	const [eduList, setEduList] = useState([])
	const { seo } = dataLangSc ? dataLangSc : dataLang
	const SEODefaultImg = coverCat?.image?.sourceUrl

	const compare = (a, b) => {
		if (a.title < b.title) {
			return -1;
		}
		if (a.title > b.title) {
			return 1;
		}
		return 0;
	}
	const facilities = data?.facilities?.nodes?.sort(compare)

	useEffect(() => {
		if (eduCategory) {
			let dataEdu = []
			facilities.forEach(el => el?.facilityCategories?.nodes?.forEach(el2 => {
				if (el2?.slug === eduCategory) {
					dataEdu?.push(el)
				}
			}))
			setEduList(dataEdu)
		} else {
			setEduList(facilities)
		}
	}, [facilities])




	return (
		<Layout theme={bgColor || "light"} path={location.pathname} lang={lang}>
			<SEO
				title={seo.title}
				desc={seo.metaDesc}
				fbImg={SEODefaultImg}
				fbTitle={seo.opengraphTitle}
				fbDesc={seo.opengraphDescription}
				url={location.href}
				twitterTitle={seo.twitterTitle}
				twitterDesc={seo.twitterDescription}
				twitterImg={SEODefaultImg}
			/>
			<GlobalCover breadcrumb={breadPath} location={location} data={coverSc ? coverSc : coverCat} theme={bgColor || "light"} />
			<FacilitiesDetailList data={eduList} category={pageContext.category.slug} />
		</Layout>
	)
}
